import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import chart1 from '../../../images/adverse_reactions_chart.png'

const metaTags = {
  description: 'Read about safety and adverse reactions associated with SUPPRELIN® LA (histrelin acetate) subcutaneous implant.',
  keywords: 'Homepage',
  title: 'Safety & Tolerability Profile | SUPPRELIN® LA (histrelin acetate)'
}

const externalLinksMetaTags = {
  category: 'External Link',
  action: 'Click',
  label: 'Deerfield Homepage',
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h1>Safety and tolerability profile<sup>1</sup></h1>
        <h2>The safety of SUPPRELIN<sup>&reg;</sup> LA in children with CPP was evaluated in 2 single-arm clinical trials conducted in a total of 47 patients (44 females and 3 males).</h2>
        <p>This table shows the implantation adverse reactions of SUPPRELIN<sup>&reg;</sup> LA subcutaneous implant over a period of time ranging from 9 to 18 months. </p>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <img src={chart1} alt="Adverse Reaction Table" title="Adverse Reaction Table" />
      </Col>
    </Row>
    <Row>
      <Col md={6} xs={12}>
        <ul className='brand-list'>
          <li>
            Implant-site reactions included: discomfort, bruising, soreness, pain, tingling, itching, implant-area protrusion, and swelling<sup>1</sup>
          </li>
          <li>
            One patient discontinued therapy due to infection at the implant site<sup>1</sup>
          </li>
          <li>
            In a Phase 3, 12-month trial, the majority of implant-site reactions were mild to moderate, and most resolved within 1 to 2 weeks without treatment<sup>2</sup>
          </li>
        </ul>
      </Col>
      <Col xs={12} md={6}>
        <ul className='brand-list'>
          <li>
          In the long-term extension phase of the trial, the most frequently reported treatment-emergent adverse events reported by 19 patients (52.8%) over 6 years were mild to moderate implant-site reactions<sup>3</sup>
          </li>
          <li>
          In the long-term extension phase, the rate of implant breakage was 22.1% over 6 years<sup>3</sup>
          </li>
        </ul>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <p className='no-mb-10'><strong>SERIOUS ADVERSE REACTIONS</strong></p>
        <p className='no-mb-10'>Two patients experienced a serious adverse reaction in the 12-month study<sup>1,3</sup>: </p>
        <ul className="brand-list">
          <li> One patient with Stargardt's Disease experienced amblyopia </li>
          <li> One patient had a benign pituitary tumor (pituitary adenoma) </li>
        </ul>
        <p>Serious adverse events were reported in the long-term extension phase, including: depression (n=1), aggression (n=2) and apnea and acute bronchopneumonia (n=1). One patient died during the extension phase from acute bronchopneumonia and Dravet syndrome.<sup>3</sup> </p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/procedure'}
          LinkCaption="Explore the implant, Implantation Kit, and implantation and removal procedures - safety tolerability profile"
        >
          Explore the implant, Implantation Kit, and implantation and removal procedures
        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/support-center-and-savings"
          LinkCaption="Learn how the Support Center assists with insurance coverage and access - safety tolerability profile"
        >
          Learn how the Support Center assists with insurance coverage and access
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
